/* .root {
  flex-grow: 1;
  display: "flex";
  flex-direction: "row";
  width: "vw";
  justify-content: "center";
} */

/* @media only screen and (min-width: 600px) {
  .mainPanel {
    width: 600px;
    background-color: red;
  }
} */
/* 
@supports (display: flex) {
  @media screen and (min-width: 701px){
    .mainPanel{
      position: absolute;
      top: 5%;
      width: 100vw;
      /* left: 10%; */
      /* transform: translateX(-50%) translateY(-50%); */
      /* background-color: lightblue; */
      /* display: flex; */
      /* display: -webkit-flex-inline; Safari */
      /* -webkit-flex-direction: row-reverse; Safari 6.1+ */
      /* display: flex-inline; */
      /* flex-direction: row-reverse;*/
      /* justify-content: center;  */
    /* } */
    /* .framePanel{ */
      /* background-color: yellowgreen; */
      /* background-color: lightblue; */
      /* width: 100%; */
      /* height: 100vh; */
      /* display: inline; */
      /* display: -webkit-flex-inline; Safari */
      /* -webkit-flex-direction: row-reverse; Safari 6.1+ */
      /* display: flex-inline; */
      /* flex-direction: row-reverse;*/
      /* justify-content: center;  */
      /* text-align: center; */
    /* } */
  /* } */
/* } */

/* @supports (display: flex) {
  @media screen and (max-width: 700px){
    .mainPanel {
      /* background-color: #add8e6; */
      /* background-color: tomato;
      height: 10%;
    }
    .framePanel{
      background-color: lightblue; */
      /* background-color: pink; */
      /* width: 100%; */
      /* width: 100vw; */
      /* height: 100vh; */
      /* text-align: center; */
      /* display: inline; */
      /* display: -webkit-flex; Safari */
      /* -webkit-flex-direction: row-reverse; Safari 6.1+ */
      /* display: flex; */
      /* flex-direction: row-reverse; */
      /* justify-content: center; */
    /* } */
  /* } */
/* }  */

@supports (display: flex) {
  .mainPanel{
    /* top: 15%; */
    width: 80%;
    height: 80%;
    /* left: 10%; */
    /* transform: translateX(-50%) translateY(-50%); */
    background-color: lightblue;
    /* display: flex; */
    display: -webkit-flex-inline; /* Safari */
    /* -webkit-flex-direction: row-reverse; Safari 6.1+ */
    /* display: flex; */
    /* flex-direction: row-reverse;*/
    justify-content: center; 
    margin-right: auto;
    margin-left: auto;
  }
  .framePanel{
    background-color: yellowgreen;
    /* background-color: lightblue; */
    /* width: 100vw;
    height: 100vh; */
    display: block;
    display: -webkit-flex-inline; /* Safari */
    /* -webkit-flex-direction: row-reverse; Safari 6.1+ */
    /* display: flex-inline; */
    /* flex-direction: row-reverse;*/

  }
}

.menuButton {
  margin: 2;
}
.title {
  /* flex-grow: 1; */
  flex-shrink: 4;
  color: #feee00;
  font-size: 6vmin !important;

}
.toolbar {
  background-color: #5174e6e1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.logo {
  margin: 2vh 2vh 2vh 2vh;
  height: 10vmin;
}

.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear;
  animation-duration: 4000ms;
  animation-direction: alternate-reverse; */
  margin: 2vh 20vh 0 5vh;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #5174e6e1;
  /* min-height: 100vh; */
  display: flex;
  height: 15vmin;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: left; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .menuIcon{
  width: 10vmin;
  height: 10vmin;
  background-color: red;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(760deg);
  }
}

@keyframes App-logo-slide {
  from {
    margin-left: 70%;
    width: 80%;
  }

  to {
    margin-right: 80%;
    width: 80%;
  }
}
